<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		size="lg"
		cancel-variant="outline-primary"
		:title="FormMSG(25, 'Modify EFC')"
		button-size="md"
		:ok-title="FormMSG(26, 'Save')"
		:cancel-title="FormMSG(27, 'Cancel')"
		ok-variant="primary"
		:ok-disabled="newEFC === ''"
		v-model="isOpen"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="handleSubmit"
		@cancel="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="form">
			<b-row>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(28, 'Category')">
						<b-form-input v-model="dataToEdit.category" disabled />
					</b-form-group>
				</b-col>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(29, 'Current EFC')">
						<b-input-group>
							<b-form-input :value="number_format(dataToEdit.currentEfc, 0, '.', ',')" disabled />
							<b-input-group-prepend>
								<b-input-group-text>
									<Euro :size="16" />
								</b-input-group-text>
							</b-input-group-prepend>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="FormMSG(30, 'Modification method')">
						<b-form-select v-model="action" :options="optionsAction" size="md" />
					</b-form-group>
				</b-col>
				<b-col v-if="action > 0" xl="6" lg="6" md="6" sm="12">
					<b-form-group :label="labelAction" :state="manageErrorEFC">
						<b-input-group>
							<b-form-input v-model="newEFC" required aria-describedby="input-efc-feedback" :state="manageErrorEFC" />
							<b-input-group-prepend>
								<b-input-group-text>
									<Euro :size="16" />
								</b-input-group-text>
							</b-input-group-prepend>
							<b-form-invalid-feedback v-if="manageErrorEFC === false" id="input-efc-feedback">
								{{ FormMSG(218, 'Value must greater zero') }}
							</b-form-invalid-feedback>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import InputSelectDoconomy from '@/components/Doconomy/InputSelectDoconomy';
import { Percent, Search, X as Reset, Euro } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import CurrencySvg from '@/components/Svg/Currency';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import _ from 'lodash';
import GlobalMixin from '@/mixins/global.mixin';
import { updateBudgetDetail } from '@/cruds/categorie.crud';

export default {
	name: 'BudgetCategorieModal',

	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	props: {
		open: { type: Boolean, default: false },
		budget: { type: Object, default: null }
	},

	components: {
		InputSelectDoconomy,
		CurrencySvg,
		Treeselect,
		Percent,
		Search,
		Reset,
		Euro
	},

	data() {
		return {
			treeCatsSelect: [],
			dataToEdit: {
				category: '',
				currentEfc: 0
			},
			manageErrorEFC: null,
			action: 0,
			newEFC: 0
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		optionsAction() {
			return [
				{
					value: 0,
					text: this.FormMSG(49, 'Choice a action')
				},
				{
					value: 1,
					text: this.FormMSG(46, 'Add')
				},
				{
					value: 2,
					text: this.FormMSG(47, 'Substract')
				},
				{
					value: 3,
					text: this.FormMSG(48, 'Change amount')
				}
			];
		},
		labelAction() {
			switch (this.action) {
				case 1:
					return this.FormMSG(65, 'Amount to add');
				case 2:
					return this.FormMSG(66, 'Amount to substract');
				case 3:
					return this.FormMSG(67, 'New EFC amount');
				default:
					return '';
			}
		}
	},

	watch: {
		budget: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.dataToEdit.currentEfc = newVal.budgetDetailInitial;
					this.dataToEdit.category = newVal.name;
				}
			}
		},
		immediate: true,
		deep: true
	},

	methods: {
		async handleSubmit() {
			if (!this.checkRequiredEFC()) return;

			let estimatedFinalCost = this.calculEFCToSend();

			await updateBudgetDetail(this.budget.budgetDetailId, {
				estimatedFinalCost
			});

			this.createToastForMobile(this.FormMSG(232, 'Success'), this.FormMSG(233, 'Estimated final cost updated successfully'));

			this.$emit('modify-EFC-modal:success', this.budget);
			this.emitEventClose();
		},
		calculEFCToSend() {
			let result = 0;
			if (this.action === 1) {
				result = parseFloat(this.dataToEdit.currentEfc) + parseFloat(this.newEFC);
			}
			if (this.action === 2) {
				result = parseFloat(this.dataToEdit.currentEfc) - parseFloat(this.newEFC);
			}
			if (this.action === 3) {
				result = parseFloat(this.newEFC);
			}

			return result;
		},
		checkRequiredEFC() {
			if (parseInt(this.newEFC, 10) === 0 || this.newEFC === '') {
				this.manageErrorEFC = false;
				return false;
			} else {
				this.manageErrorEFC = null;
				return true;
			}
		},
		emitEventClose() {
			this.resetData();
			this.$emit('modify-EFC-modal:close');
		},
		resetData() {
			this.dataToEdit = {
				category: '',
				currentEfc: 0
			};
			this.action = 0;
			this.newEFC = 0;
			this.manageErrorEFC = null;
		}
	}
};
</script>

<style scoped></style>
