var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "lg",
        "cancel-variant": "outline-primary",
        title: _vm.FormMSG(2001, "Budget category detail"),
        "button-size": "md",
        "ok-title": _vm.FormMSG(201, "Save"),
        "cancel-title": _vm.FormMSG(246, "Cancel"),
        "ok-variant": "primary",
        "no-close-on-backdrop": true,
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        cancel: _vm.emitEventClose,
        show: _vm.getCategories,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xl: "6", lg: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(141, "Category name") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.FormMSG(141, "Category name"),
                        },
                        model: {
                          value: _vm.dataToEdit.categoryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "categoryName", $$v)
                          },
                          expression: "dataToEdit.categoryName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xl: "6", lg: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: _vm.dataToEdit.contentType === 0 ? 6 : 12,
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(142, "Cost center") },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.FormMSG(142, "Cost center"),
                                },
                                model: {
                                  value: _vm.dataToEdit.costCenter,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataToEdit, "costCenter", $$v)
                                  },
                                  expression: "dataToEdit.costCenter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dataToEdit.contentType === 0
                        ? _c(
                            "b-col",
                            { staticClass: "pt-28", attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    size: "lg",
                                    value: true,
                                    "unchecked-value": false,
                                  },
                                  model: {
                                    value: _vm.dataToEdit.visibleInApp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataToEdit,
                                        "visibleInApp",
                                        $$v
                                      )
                                    },
                                    expression: "dataToEdit.visibleInApp",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(143, "Visible for crew")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xl: "4", lg: "4", md: "4", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(144, "Parent category") } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: false,
                          options: _vm.mapCategories,
                          "disable-branch-nodes": true,
                          clearable: false,
                          placeholder: _vm.FormMSG(297, "Select a category"),
                          disabled:
                            _vm.action === "add-sub" || _vm.action === "edit",
                        },
                        on: { select: _vm.handleSelectParentCategory },
                        scopedSlots: _vm._u([
                          {
                            key: "option-label",
                            fn: function ({ node }) {
                              return _c("div", {}, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "treeselect-label",
                                    attrs: { title: node.label },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(node.label) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ])
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dataToEdit.parentCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "parentCategory", $$v)
                          },
                          expression: "dataToEdit.parentCategory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xl: "4", lg: "4", md: "4", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(169, "Type") } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.contentTypeMenu,
                          disabled:
                            _vm.dataToEdit.departmentNumber === 0 ||
                            _vm.budget.budgetDetailIsLinkedToFunctionId > 0 ||
                            _vm.action === "add-sub",
                        },
                        model: {
                          value: _vm.dataToEdit.contentType,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToEdit, "contentType", $$v)
                          },
                          expression: "dataToEdit.contentType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataToEdit.contentType === 0
                ? _c(
                    "b-col",
                    { attrs: { xl: "4", lg: "4", md: "4", sm: "12" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(145, "CO2 Category") } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.co2CategoriesMenu },
                            model: {
                              value: _vm.dataToEdit.co2Category,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "co2Category", $$v)
                              },
                              expression: "dataToEdit.co2Category",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xl: "6", lg: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(146, "Budget amount") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { disabled: _vm.budgetIsLocked },
                            on: { input: _vm.handleInputBudgetAmount },
                            model: {
                              value: _vm.dataToEdit.budgetAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "budgetAmount", $$v)
                              },
                              expression: "dataToEdit.budgetAmount",
                            },
                          }),
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [_c("Euro", { attrs: { size: 16 } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xl: "6", lg: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(147, "Estimated final cost (EFC)"),
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.dataToEdit.efc,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "efc", $$v)
                              },
                              expression: "dataToEdit.efc",
                            },
                          }),
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [_c("Euro", { attrs: { size: 16 } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xl: "6", lg: "6", md: "6", sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(148, "Initial spent") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.dataToEdit.initialSpent,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToEdit, "initialSpent", $$v)
                              },
                              expression: "dataToEdit.initialSpent",
                            },
                          }),
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [_c("Euro", { attrs: { size: 16 } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          149,
                          "* To set additional option on the category, please go under Configuration/Project - Tab Budget categories."
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }